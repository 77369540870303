/* Body font */
/* Montserrat */

@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@200;300;400&display=swap');

body {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
}


/* Heading font */
/* Blinker */
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  font-family: "Blinker", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
}

.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 20vh;
  pointer-events: none;
}

.App-header { 
  display: flex; 
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.back-btn{
  /* position: absolute;
  top:1em;
  left:1em; */
}

.fetchall-btn{
  position: absolute;
  top:2em;
  right:4em;
 
}


body{
  background-color:#000 ;
  color: #ddd;
}
h3{
  color:  #e51743;
}
.uppercase{
  text-transform: uppercase;
}
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}
.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 30px;
}
.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}
.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: #e51743;
}

.login div {
  margin-top: 7px;
}

table{
  text-align: center;
}
.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
 
}

 .trow {
  display: table-row;
} 

.cell {
  display: table-cell;
  padding: 10px;
  border: 1px solid #ddd;
  color: white;
  text-align: center;
}

.roundButton {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  min-width: 50px;  
  color: #e51743;
}

.pointer{
  cursor: pointer;
}

.pnr-counts {
  margin-top: 0.5rem;
}

.pnr-counts span {
  display: inline-block;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  background-color: #f8f9fa;
}


.pnr-count {
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.pnr-count:hover {
  background-color: #d9dcdf;
}

.pnr-count.active {
  background-color: #007bff;
  color: white !important;
}

.qr-container {
  min-height: calc(70vh - 100px); /* Adjust the value based on your header height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-content {
  text-align: center;
}

.qr-icon {
  margin: 20px 0;
}

.card {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.border.rounded {
  transition: all 0.3s ease;
}

.border.rounded:hover {
  background-color: #f8f9fa;
  transform: translateY(-2px);
}

.table-responsive {
  max-height: 500px;
  overflow-y: auto;
}

.search-box .input-group {
 
  overflow: hidden;
}

.search-box .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.search-box .input-group-text {
  background-color: white;
  border-right: none;
}

.search-box .form-control {
  border-left: none;
}

.search-box .btn-outline-secondary {
  border: 1px solid #ced4da;
  border-left: none;
}

.search-box .btn-outline-secondary:hover {
  background-color: #f8f9fa;
}
/* Add to your existing CSS */
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.club-name-link:hover {
  text-decoration: underline;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.modal-backdrop {
  z-index: 1040;
}

body.modal-open {
  overflow: hidden;
  padding-right: 17px; /* Prevents layout shift */
}

.modal-dialog {
  z-index: 1055;
}

/*  Footer css  */

.footer{
  display:none;
   
}


/* End  */

/* Scanner table  */

.ticket-details-container {
  width: 100%;
 
}
.ticket-details-container .cell{
  text-align: left;
}

.trow {
  display: flex;

  width: 100%;
}

.cell {
  padding: 12px 15px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.cell:first-child {
  width: 30%; 
  font-weight: 500;
}

.cell:last-child {
  width: 70%;
}

/* For mobile screens */
@media (max-width: 768px) {
  .cell:first-child {
    width: 40%;
  }
  
  .cell:last-child {
    width: 60%;
  }
  
  .cell {
    padding: 10px;
    font-size: 0.8rem;
  }
}

/* For the club row specifically */
.cell .d-flex {
  gap: 10px;
}

.btn-sm {
  white-space: nowrap;
}

/*   */